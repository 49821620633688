@import '../../../styles/variables.scss';

.footer {
	background: #1B182B;
	padding: 44px 80px;

	@media (max-width: $mobile) {
		padding: 44px 20px;
	}
	.top-section {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 20px;

		@media (max-width: $mobile) {
			flex-direction: column;
		}

		.left-section {
			.logo {
				width: 200px;
				height: 20px;
			}
		}
		.right-section {
			display: flex;
			justify-content: flex-end;
			align-items: center;

			@media (max-width: $mobile) {
				margin-top: 20px;
			}

			.left {
				.text {
					font-family: 'Roboto', sans-serif;
					color: #645E85;
					text-align: right;
					font-size: 24px;
					font-style: normal;
					font-weight: 400;
					line-height: 36px;

					@media (max-width: $mobile) {
						text-align: left;
						font-size: 20px;
					}
				}
			}
			.divider {
				margin: 0 32px !important;
				height: 36px !important;

				@media (max-width: $mobile) {
					margin: 0 8px !important;
				}
			}
			.right {
				display: flex;
				align-items: center;
				.link-icon {
					margin-right: 16px;
					// if the last item, remove the margin
					&:last-child {
						margin-right: 0;
					}
					.icon {
						width: 24px;
						height: 24px;
					}
				}
			}
		}
	}
	.bottom-section {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: 20px;
		.text-starcade {
			font-family: 'Roboto', sans-serif;
			color: #645E85;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 36px;
		}
		.text-terms {
			font-family: 'Roboto', sans-serif;
			color: #645E85;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 36px;
			text-align: right;
		}
	}
}
