@import '../../../../styles/variables.scss';

.bug-hunter-terms {
    display: flex;
    flex-direction: column;
    padding: 0 80px;
    @media (max-width: $mobile) {
        padding: 0 40px;
    }
    .accordion{
        display: flex;
        flex-direction: column;
        .header {
            border-radius: 32px 32px 0 0;
            background: #3A3650;
            padding: 32px 40px;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .text {
                color: #FFF;
                font-family: Roboto, sans-serif;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 36px; /* 150% */
                @media (max-width: $mobile) {
                    font-size: 20px;
                }
            }
            img {
                width: 36px;
                height: 36px;
                transition: all 0.5s ease-out;
                @media (max-width: $mobile) {
                    width: 24px;
                    height: 24px;
                }
                &.closed {
                    transform: rotate(180deg);
                }
            }
        }
        .content {
            border-radius: 0 0 32px 32px;
            background: #1C1932;
            height: unset;
            padding: 28px 40px;
            transition: all 0.5s ease-out;
            &.closed {
                display: none;
            }
            .description {
                color: #B0A2AF;
                font-family: Roboto, sans-serif;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%; /* 38.4px */
                margin-top: 78px;
                @media (max-width: $mobile) {
                    font-size: 16px;
                    margin-top: 39px;
                }
            }
            .item {
                display: flex;
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 0;
                }
                &.sub {
                    margin-left: 40px;
                }
                img {
                    width: 24px;
                    height: 24px;
                    margin-right: 12px;
                    margin-top: 6px;
                    @media (max-width: $mobile) {
                        width: 16px;
                        height: 16px;
                        margin-right: 8px;
                        margin-top: 4px;
                    }
                }
                .text {
                    color: #B0A2AF;
                    font-family: Roboto, sans-serif;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 160%; /* 38.4px */
                    @media (max-width: $mobile) {
                        font-size: 16px;
                    }
                    .yellow {
                        color: #FFC700;
                        cursor: pointer;
                    }
                    .italic {
                        font-style: italic;
                    }
                }
            }
        }
    }
}
