@import '../../../../styles/variables.scss';

.arcade-info {
	padding: 80px 0;
	.title {
		font-family: 'Roboto', sans-serif;
		color: #B0A2AF;
		text-align: center;
		font-size: 56px;
		font-style: normal;
		font-weight: 800;
		line-height: 110%;
		margin-bottom: 40px;
		@media (max-width: $mobile) {
			font-size: 32px;
		}
	}
	.info {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-auto-rows: 1fr;
		grid-column-gap: 40px;
		grid-row-gap: 40px;
		margin: 0 80px;

		@media (max-width: $mobile) {
			grid-template-columns: repeat(1, 1fr);
			margin: 0 40px;
		}

		.box {
			border-radius: 32px;
			background: #3A3650;
			padding: 32px;
			display: flex;
			flex-direction: column;
			align-items: center;
			position: relative;
			.image {
				width: 240px;
				height: 240px;
				z-index: 2;
				@media (max-width: $mobile) {
					width: 160px;
					height: 160px;
				}
			}
			.circle {
				width: 240px;
				height: 240px;
				background: #4A4663;
				border-radius: 9999px;
				filter: blur(32px);
				position: absolute;
				@media (max-width: $mobile) {
					width: 160px;
					height: 160px;
				}
			}
			.title {
				color: #FFF;
				text-align: center;
				font-size: 40px;
				font-style: normal;
				font-weight: 700;
				line-height: 160%;
				margin-top: 4px;
				margin-bottom: 8px;
				@media (max-width: $mobile) {
					font-size: 28px;
				}
			}
			.description {
				color: #B0A2AF;
				text-align: center;
				font-size: 24px;
				font-style: normal;
				font-weight: 400;
				line-height: 160%;
				@media (max-width: $mobile) {
					font-size: 20px;
				}
			}
		}
	}
}
