@import '../../../../styles/variables.scss';

.explore {
	padding: 80px 0;
	.title {
		color: #B0A2AF;
		text-align: center;
		font-size: 56px;
		font-style: normal;
		font-weight: 800;
		line-height: 110%;
		margin-bottom: 40px;
		@media (max-width: $mobile) {
			font-size: 32px;
		}
	}
	.download-box {
		border-radius: 32px;
		background: #3A3650;
		margin: 0 80px;
		margin-bottom: 20px;
		padding: 40px 56px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: $mobile) {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin: 0 40px 40px 40px;
		}

		.left {
			display: flex;
			align-items: center;
			@media (max-width: $mobile) {
				flex-direction: column;
			}
			.icon-circle {
				padding: 10px;
				background: #4E4A65;
				border-radius: 9999px;
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				.image {
					width: 42px;
					height: 42px;
				}
				.image-blur {
					width: 42px;
					height: 42px;
					box-shadow: 8px 8px 8px;
					filter: blur(8px);
					position: absolute;
				}
			}
			.download-text {
				color: #B0A2AF;
				font-size: 24px;
				font-style: normal;
				font-weight: 400;
				line-height: 40px;
				margin-left: 28px;
				@media (max-width: $mobile) {
					margin-left: 0;
					margin-top: 20px;
					text-align: center;
					font-size: 20px;
				}
			}
		}
		.right {
			@media (max-width: $mobile) {
				margin-top: 20px;
			}
			.download-image {
				width: 214px;
				height: 64px;
			}
		}
	}

	.carousel{
		margin: 0 80px;
		display: flex;
		flex-direction: column;
		position: relative;

		@media (max-width: $mobile) {
			margin: 0 40px;
		}

		.game-box {
			height: 300px;
			border-radius: 32px;
			border: 4px solid #E587EB;
			background: linear-gradient(137deg, #AC55B0 0%, #380E4F 100%);
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-right: 36px;

			@media (max-width: $mobile) {
				flex-direction: column;
				justify-content: center;
				height: 575px;
				margin-right: 20px;
			}

			.game-image {
				height: 300px;
				object-fit: contain;
				@media (max-width: $mobile) {
					height: 225px;
				}
			}
			.game-info {
				padding: 48px 40px;
				@media (max-width: $mobile) {
					padding: 24px 20px;
				}
				.game-name {
					color: #FFF;
					font-size: 40px;
					font-style: normal;
					font-weight: 700;
					line-height: 160%;
					@media (max-width: $mobile) {
						font-size: 28px;
						text-align: center;
					}
				}
				.divider {
					margin: 12px 0 !important;
				}
				.game-description {
					color: #E2D8E2;
					font-size: 24px;
					font-style: normal;
					font-weight: 400;
					line-height: 160%;
					@media (max-width: $mobile) {
						font-size: 20px;
						text-align: center;
					}
				}
			}
		}
		.button-group {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 40%;
			margin-top: 20px;
			align-self: center;

			@media (max-width: $mobile) {
				width: 100%;
			}

			.button {
				padding: 12px;
				border-radius: 20px;
				background: #3A3650;
				cursor: pointer;
				z-index: 5;
				.chevron-icon {
					width: 56px;
					height: 56px;
				}
			}
		}
		.dot-inactive {
			width: 16px;
			height: 16px;
			border-radius: 16px;
			background: #3A3650;
			margin-bottom: 30px;
			margin-right: 5px;
			cursor: pointer;

			&:last-child {
				margin-right: 0;
			}
			
			@media (max-width: 375px) {
				width: 10px;
				height: 10px;
				margin-bottom: 40px;
			}
		}
		.dot-active {
			width: 50px;
			height: 16px;
			border-radius: 16px;
			background: #da13c6;
			margin-bottom: 30px;
			margin-right: 5px;
			cursor: pointer;

			&:last-child {
				margin-right: 0;
			}
			
			@media (max-width: 375px) {
				width: 30px;
				height: 10px;
				margin-bottom: 40px;
			}
		}
	}
}
