@import '../../../styles/colors';

.text {
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	padding: 0;
	margin: 0;
}

.dark {
	@extend .text;
	color: $text-white;
}

.light {
	@extend .text;
	color: $text-black;
}
