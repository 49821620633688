@import '../../../../styles/variables.scss';

.bug-hunter-description {
    display: flex;
    flex-direction: column;
    padding: 0 80px 75px;
    align-items: center;
    @media (max-width: $mobile) {
        padding: 0 40px 32px;
    }
    .title {
        color: #B0A2AF;
        text-align: center;
        font-family: Roboto, sans-serif;
        font-size: 56px;
        font-style: normal;
        font-weight: 800;
        margin-bottom: 40px;
        max-width: 95%;
        @media (max-width: $mobile) {
            font-size: 28px;
            margin-bottom: 24px;
            max-width: unset;
        }
    }
    .box-content {
        display: flex;
        flex-direction: column;
        padding-bottom: 40px;
        @media (max-width: $mobile) {
            padding: 0 20px 20px;
            width: fit-content;
        }
        .upper {
            display: flex;
            justify-content: space-between;
            padding: 32px;
            background: #3A3650;
            border-radius: 32px 32px 0 0;
            @media (max-width: $mobile) {
                padding: 16px;
                flex-direction: column;
            }
            .content-container {
                display: flex;
                flex: 0.7;
                flex-direction: column;
                @media (max-width: $mobile) {
                    flex: unset;
                    width: 80vw;
                }
                .container {
                    margin-bottom: 25px;
                    @media (max-width: $mobile) {
                        margin-bottom: 16px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    .content-text {
                        color: #B0A2AF;
                        font-family: Roboto, sans-serif;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 160%; /* 38.4px */
                        @media (max-width: $mobile) {
                            font-size: 18px;
                        }
                        .bolded {
                            color: #FFC700;
                            font-weight: 700;
                        }
                    }
                    .content-title {
                        color: #FFF;
                        font-family: Roboto, sans-serif;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: 160%;
                        @media (max-width: $mobile) {
                            font-size: 18px;
                        }
                    }
                }
            }
            .image-container {
                border-radius: 32px;
                background: #016BFF;
                height: 324px;
                display: flex;
                flex: 0.25;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                @media (max-width: $mobile) {
                    flex: unset;
                    width: 80vw;
                    margin: 16px 0;
                }
                img {
                    border-radius: 32px;
                    object-fit: contain;
                    @media (max-width: $mobile) {
                        width: 100%;
                    }
                }
            }
        }
        .lower {
            display: flex;
            align-items: center;
            padding: 24px 40px;
            background: #8326BB;
            border-radius: 0 0 32px 32px;
            @media (max-width: $mobile) {
                padding: 12px 20px;
            }
            img {
                width: 36px;
                height: 36px;
                object-fit: contain;
                margin-right: 16px;
                @media (max-width: $mobile) {
                    width: 24px;
                    height: 24px;
                    margin-right: 8px;
                }
            }
            .text {
                color: #FFF;
                font-family: Roboto, sans-serif;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 36px; /* 150% */
                @media (max-width: $mobile) {
                    font-size: 18px;
                    line-height: unset;
                }
            }
        }
    }
    .button-container {
        max-width: 500px;
        align-self: center;
        border-radius: 48px;

        @media (max-width: $mobile) {
            padding: 6px 12px;
            border-radius: 24px;
        }
        .button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            min-width: 240px;

            @media (max-width: $mobile) {
                min-width: unset;
            }

            .button-text {
                color: #FFF;
                font-family: Roboto, sans-serif;
                font-size: 36px;
                font-style: normal;
                font-weight: 700;
                line-height: 48px; /* 133.333% */
                letter-spacing: 0.5px;
                padding: 16px 24px;

                @media (max-width: $mobile) {
                    font-size: 18px;
                    padding: 8px 12px;
                    line-height: unset;
                }
            }
            .button-icon {
                width: 48px;
                height: 48px;
                margin-left: 16px;
                @media (max-width: $mobile) {
                    width: 32px;
                    height: 32px;
                    margin-left: 8px;
                }
            }
        }
    }
}
