@import '../../../../styles/variables.scss';

.contact-us {
	padding: 80px;

	@media (max-width: $mobile) {
		padding: 80px 40px;
	}

	.content {
		border-radius: 32px;
		background: #3A3650;
		padding: 40px;
		display: flex;
		flex-direction: column;

		.top {
			display: flex;
			justify-content: space-between;

			@media (max-width: $mobile) {
				flex-direction: column;
			}

			.left {
				flex: 0.35;
				.title {
					color: #B0A2AF;
					font-size: 56px;
					font-style: normal;
					font-weight: 800;
					line-height: 110%;
					margin-bottom: 16px;
					@media (max-width: $mobile) {
						font-size: 32px;
						text-align: center;
					}
				}
				.description {
					color: #B0A2AF;
					font-size: 20px;
					font-style: normal;
					font-weight: 400;
					line-height: 24px;
					@media (max-width: $mobile) {
						font-size: 16px;
						text-align: center;
					}
				}
			}
			.right {
				flex: 0.6;
				display: flex;
				flex-direction: column;

				@media (max-width: $mobile) {
					margin-top: 24px;
				}

				.name-email-container {
					display: grid;
					grid-template-columns: 1fr 1fr;
					column-gap: 20px;
					margin-bottom: 16px;

					@media (max-width: $mobile) {
						grid-template-columns: 1fr;
						row-gap: 20px;
					}

					.form-name-email {
						border-radius: 12px;
						border: 1px solid #D713C4;
						background: #3A3650;
						padding: 8px 16px;

						color: #B0A2AF;
						font-size: 24px;
						font-style: normal;
						font-weight: 400;
						line-height: 40px;

						@media (max-width: $mobile) {
							width: calc(100% - 32px);
							font-size: 20px;
						}
					}
				}
				.form {
					border-radius: 12px;
					border: 1px solid #D713C4;
					background: #3A3650;
					padding: 8px 16px;
					color: #B0A2AF;
					font-size: 24px;
					font-style: normal;
					font-weight: 400;
					line-height: 40px;
					resize: none;

					@media (max-width: $mobile) {
						font-size: 20px;
					}
				}
			}
		}
		.bottom {
			margin-top: 24px;
			display: flex;
			justify-content: flex-end;
			.button-container {
				@media (max-width: $mobile) {
					padding: 6px 12px;
				}
				.button {
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					min-width: 240px;

					@media (max-width: $mobile) {
						min-width: unset;
					}

					.button-text {
						color: #FFF;
						font-size: 24px;
						font-style: normal;
						font-weight: 700;
						line-height: 24px;
						letter-spacing: 0.5px;
						padding: 8px 0;

						@media (max-width: $mobile) {
							font-size: 16px;
						}
					}
					.button-icon {
						width: 24px;
						height: 24px;
						margin-left: 16px;
					}
				}
			}
		}
	}
}
