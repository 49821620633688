@import "../../styles/variables.scss";

.ios-beta-test {
    background: linear-gradient(118deg, #383456 0%, #1F1E28 84.69%) no-repeat;
    min-height: calc(100vh - 301px);
    padding-top: 100px;
    @media (max-width: $mobile) {
        min-height: calc(100vh - 345px);
    }
}
