@import '../../../styles/colors';
@import '../../../styles/variables';

.navbar {
	height: $nav-height;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	display: flex;
	align-items: center;
	background-color: $nav;
	padding: 24px 80px;
	.left-side {
		flex: 1;
		display: flex;
		align-items: center;
		.logo-link {
			margin-right: 46px;
			.logo {
				width: 200px;
				height: 20px;
			}
		}
		.navbar-item-container {
			display: flex;
			@media (max-width: $mobile) {
				display: none;
			}
			
			.link-container {
				display: flex;
				flex-direction: column;
				position: relative;
				align-items: center;
				// if not last item add margin right
				&:not(:last-child) {
					margin-right: 24px;
				}
				.link {
					font-family: 'Roboto', sans-serif;
					color: #FFF;
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: 24px;
					letter-spacing: 0.5px;

					&.active {
						color: #D913C6;
						font-weight: 700;
					}
				}

				.active-indicator {
					width: 115%;
					height: 8px;
					flex-shrink: 0;
					border-radius: 4px;
					background: #D913C6;
					position: absolute;
					bottom: -35px;
				}
			}
		}
	}
	.right-side {
		display: none;
		@media (max-width: $mobile) {
			display: flex;
		}

		.hamburger-icon-link {
			.hamburger-icon {
				width: 24px;
				height: 24px;
			}
		}
	}
}

.navbar-sidemenu {
	width: 100vw;
	height: 100vh;
	position: fixed;
	transform: translateX(100vw);
	transition: transform 0.25s ease-out;
	background: linear-gradient(118deg, #383456 0%, #1F1E28 84.69%) no-repeat;
	z-index: 102 !important;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	&.active {
		transform: translateX(0);
	}

	.close-icon {
		display: flex;
		justify-content: flex-end;
		padding: 24px;

		.close {
			width: 35px;
			height: 35px;
		}
	}

	.link {
		font-family: 'Roboto', sans-serif;
		color: #FFF;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: 0.5px;
		padding: 0 24px;
		// if not last item add margin bottom
		&:not(:last-child) {
			margin-bottom: 24px;
		}
	}
}
