@import '../../../../styles/variables.scss';

.beta-description {
    display: flex;
    flex-direction: column;
    padding: 0 80px 108px;

    @media (max-width: $mobile) {
        padding: 0 40px 54px;
    }

    .title {
        color: #FFF;
        text-align: center;
        font-family: Roboto, sans-serif;
        font-size: 56px;
        font-style: normal;
        font-weight: 800;
        padding-top: 64px;
        padding-bottom: 60px;
        @media (max-width: $mobile) {
            font-size: 40px;
            padding-top: 32px;
            padding-bottom: 32px;
        }
    }
    .description-item {
        margin-bottom: 32px;
        padding: 32px;
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-radius: 32px;
        background: #3A3650;
        @media (max-width: $mobile) {
            flex-direction: column;
            padding: 16px;
        }
        .left-item-1 {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 0.475;
            border-radius: 32px;
            background: linear-gradient(180deg, #19B8F0 0%, #1765E1 100%);
            padding: 39px;
            @media (max-width: $mobile) {
                padding: 16px;
                border-radius: 16px;
            }
            img {
                width: 120px;
                height: 120px;
                @media (max-width: $mobile) {
                    width: 80px;
                    height: 80px;
                }
            }
        }
        .left-item-2 {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 0.45;
            border-radius: 32px;
            padding: 70px;
            background: url("../../../../assets/images/beta-background.png") no-repeat;
            background-size: cover;
            @media (max-width: $mobile) {
                padding: 16px;
                border-radius: 16px;
            }
            .img {
                width: 200px;
                height: 200px;
                position: relative;
                background: url("../../../../assets/images/beta-game.png") no-repeat;
                background-size: contain;
                @media (max-width: $mobile) {
                    width: 80px;
                    height: 80px;
                }
                .beta-info {
                    border-radius: 32px;
                    background: #FF2E00;
                    position: absolute;
                    top: -10px;
                    left: -20px;
                    padding: 0 16px;
                    @media (max-width: $mobile) {
                        top: -6px;
                        left: -12px;
                        border-radius: 16px;
                        padding: 4px 8px;
                    }
                    .text {
                        color: #FFF;
                        text-align: center;
                        font-family: Roboto, sans-serif;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 36px; /* 150% */
                        letter-spacing: 0.24px;
                        @media (max-width: $mobile) {
                            font-size: 14px;
                            line-height: unset;
                        }
                    }
                }
            }
        }
        .text-content {
            display: flex;
            flex: 0.475;
            flex-direction: column;
            justify-content: center;
            .content-title {
                color: #B0A2AF;
                font-family: Roboto, sans-serif;
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: 160%; /* 64px */
                @media (max-width: $mobile) {
                    font-size: 24px;
                    margin-bottom: 10px;
                    text-align: center;
                }
            }
            .content-description {
                color: #B0A2AF;
                font-family: Roboto, sans-serif;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%; /* 38.4px */
                @media (max-width: $mobile) {
                    font-size: 18px;
                }
            }

            &.content-2 {
                flex: 0.5;
            }

            .content-item {
                display: flex;
                flex-direction: row;
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 0;
                }
                @media (max-width: $mobile) {
                    margin-bottom: 10px;
                }
                .icon {
                    width: 24px;
                    height: 24px;
                    margin-top: 6px;
                    margin-right: 8px;
                    @media (max-width: $mobile) {
                        width: 16px;
                        height: 16px;
                        margin-top: 4px;
                        margin-right: 4px;
                    }
                }
                .text {
                    color: #B0A2AF;
                    font-family: Roboto, sans-serif;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 160%; /* 38.4px */
                    @media (max-width: $mobile) {
                        font-size: 18px;
                    }
                }
            }
        }
    }
    .button-container {
        max-width: 500px;
        align-self: center;
        border-radius: 48px;

        @media (max-width: $mobile) {
            padding: 6px 12px;
            border-radius: 24px;
        }
        .button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            min-width: 240px;

            @media (max-width: $mobile) {
                min-width: unset;
            }

            .button-text {
                color: #FFF;
                font-family: Roboto, sans-serif;
                font-size: 36px;
                font-style: normal;
                font-weight: 700;
                line-height: 48px; /* 133.333% */
                letter-spacing: 0.5px;
                padding: 16px 24px;

                @media (max-width: $mobile) {
                    font-size: 18px;
                    padding: 8px 12px;
                    line-height: unset;
                }
            }
            .button-icon {
                width: 48px;
                height: 48px;
                margin-left: 16px;
                @media (max-width: $mobile) {
                    width: 32px;
                    height: 32px;
                    margin-left: 8px;
                }
            }
        }
    }
}
