@import 'colors';

@mixin desktop() {
	@media (min-width: 768px) {
		@content;
	}
}

@mixin mobile() {
	@media (max-width: 768px) {
		@content;
	}
}

@mixin background-primary() {
	background-color: $color-primary;
	color: $text-white;

	@content;
}

@mixin background-secondary() {
	background-color: $color-secondary;
	color: $text-black;

	@content;
}

@mixin background-tertiary() {
	background-color: $color-tertiary;
	color: $text-white;

	@content;
}

@mixin transition($transition: all 0.5s ease-out) {
	-webkit-transition: $transition;
	-moz-transition: $transition;
	-ms-transition: $transition;
	-o-transition: $transition;
	transition: $transition;
}

@mixin normal-cursor {
	@media (prefers-color-scheme: light) {
		cursor: url('../assets/cursor/normal-light.svg'), auto;
	}
	@media (prefers-color-scheme: dark) {
		cursor: url('../assets/cursor/normal-dark.svg'), auto;
	}
}

@mixin help-cursor {
	@media (prefers-color-scheme: light) {
		cursor: url('../assets/cursor/help-light.svg'), auto;
	}
	@media (prefers-color-scheme: dark) {
		cursor: url('../assets/cursor/help-dark.svg'), auto;
	}
}

@mixin disabled-cursor {
	@media (prefers-color-scheme: light) {
		cursor: url('../assets/cursor/disabled-light.svg'), auto;
	}
	@media (prefers-color-scheme: dark) {
		cursor: url('../assets/cursor/disabled-dark.svg'), auto;
	}
}

@mixin move-cursor {
	@media (prefers-color-scheme: light) {
		cursor: url('../assets/cursor/move-light.svg'), auto;
	}
	@media (prefers-color-scheme: dark) {
		cursor: url('../assets/cursor/move-dark.svg'), auto;
	}
}

@mixin resize-alt-cursor {
	@media (prefers-color-scheme: light) {
		cursor: url('../assets/cursor/resize_alt-light.svg'), auto;
	}
	@media (prefers-color-scheme: dark) {
		cursor: url('../assets/cursor/resize_alt-dark.svg'), auto;
	}
}

@mixin resize-cursor {
	@media (prefers-color-scheme: light) {
		cursor: url('../assets/cursor/resize-light.svg'), auto;
	}
	@media (prefers-color-scheme: dark) {
		cursor: url('../assets/cursor/resize-dark.svg'), auto;
	}
}

@mixin busy-cursor {
	@media (prefers-color-scheme: light) {
		cursor: url('../assets/cursor/busy-light.svg'), auto;
	}
	@media (prefers-color-scheme: dark) {
		cursor: url('../assets/cursor/busy-dark.svg'), auto;
	}
}

@mixin background-cursor {
	@media (prefers-color-scheme: light) {
		cursor: url('../assets/cursor/background-light.svg'), auto;
	}
	@media (prefers-color-scheme: dark) {
		cursor: url('../assets/cursor/background-dark.svg'), auto;
	}
}

@mixin text-cursor {
	@media (prefers-color-scheme: light) {
		cursor: url('../assets/cursor/text-light.svg'), auto;
	}
	@media (prefers-color-scheme: dark) {
		cursor: url('../assets/cursor/text-dark.svg'), auto;
	}
}

@mixin pointer-cursor {
	@media (prefers-color-scheme: light) {
		cursor: url('../assets/cursor/pointer-light.svg'), auto;
	}
	@media (prefers-color-scheme: dark) {
		cursor: url('../assets/cursor/pointer-dark.svg'), auto;
	}
}

@mixin grab-cursor {
	@media (prefers-color-scheme: light) {
		cursor: url('../assets/cursor/grab-light.svg'), auto;
	}
	@media (prefers-color-scheme: dark) {
		cursor: url('../assets/cursor/grab-dark.svg'), auto;
	}
}

@mixin grabbing-cursor {
	@media (prefers-color-scheme: light) {
		cursor: url('../assets/cursor/grabbing-light.svg'), auto;
	}
	@media (prefers-color-scheme: dark) {
		cursor: url('../assets/cursor/grabbing-dark.svg'), auto;
	}
}

@mixin zoom-in-cursor {
	@media (prefers-color-scheme: light) {
		cursor: url('../assets/cursor/zoom_in-light.svg'), auto;
	}
	@media (prefers-color-scheme: dark) {
		cursor: url('../assets/cursor/zoom_in-dark.svg'), auto;
	}
}

@mixin zoom-out-cursor {
	@media (prefers-color-scheme: light) {
		cursor: url('../assets/cursor/zoom_out-light.svg'), auto;
	}
	@media (prefers-color-scheme: dark) {
		cursor: url('../assets/cursor/zoom_out-dark.svg'), auto;
	}
}

@mixin row($justify: center, $align: center) {
	display: flex;
	flex-direction: row;
	justify-content: $justify;
	align-items: $align;
	width: 100%;
}

@mixin column($justify: center, $align: center) {
	display: flex;
	flex-direction: column;
	justify-content: $justify;
	align-items: $align;
	width: 100%;
}
