@import '../../../../styles/variables.scss';

.banner {
	margin-bottom: 80px;
	height: 800px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;

	@media (max-width: $mobile) {
		height: 100vh;
	}

	.background {
		width: 100%;
		height: 800px;
		object-fit: cover;
		position: absolute;
		z-index: 0;

		@media (max-width: $mobile) {
			height: 100vh;
		}
	}
	.content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 45%;
		height: 800px;
		z-index: 1;
			
		@media (max-width: $mobile) {
			width: 80%;
		}

		.title {
			color: #FFF;
			text-align: center;
			font-size: 64px;
			font-style: normal;
			font-weight: 800;
			line-height: 110%;
			@media (max-width: $mobile) {
				font-size: 42px;
			}
		}

		.description {
			color: #FFF;
			text-align: center;
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%;
			margin-top: 32px;
			@media (max-width: $mobile) {
				font-size: 20px;
			}
		}

		.button-container {
			display: flex;
			justify-content: space-around;
			align-items: center;
			margin-top: 32px;

			.store-image {
				width: 160px;
				height: 48px;
			}
		}
	}

	.scroll-down-container {
		width: 100%;
		position: absolute;
		bottom: 28px;
		z-index: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.button-scroll {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.icon {
				width: 40px;
				height: 40px;
				margin-bottom: 8px;
			}
			.text {
				color: #FFF;
				text-align: center;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 160%;
			}
		}
	}
}
