@import '../../../../styles/variables.scss';

.how-to-report {
    display: flex;
    flex-direction: column;
    padding: 0 80px 75px;
    align-items: center;
    @media (max-width: $mobile) {
        padding: 0 40px 32px;
    }
    .title {
        color: #B0A2AF;
        text-align: center;
        font-family: Roboto, sans-serif;
        font-size: 56px;
        font-style: normal;
        font-weight: 800;
        margin-bottom: 40px;
        @media (max-width: $mobile) {
            font-size: 32px;
            margin-bottom: 24px;
        }
    }
    .content {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 32px;
        @media (max-width: $mobile) {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 16px;
        }
        @media (max-width: $mobile-smaller) {
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 16px;
        }
        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            border-radius: 32px;
            background: #3A3650;
            padding: 20px;
            @media (max-width: $mobile) {
                padding: 16px;
            }
            .stepper {
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 32px;
                background: #FF2E00;
                padding: 0 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                @media (max-width: $mobile) {
                    padding: 8px;
                }
                .text {
                    color: #FFF;
                    text-align: center;
                    font-family: Roboto, sans-serif;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 36px; /* 150% */
                    letter-spacing: 0.24px;
                    @media (max-width: $mobile) {
                        font-size: 16px;
                        line-height: unset;
                    }
                }
            }
            img {
                width: 100%;
                height: 160px;
                border-radius: 32px;
                margin-bottom: 16px;
                object-fit: cover;
            }
            .text {
                color: #B0A2AF;
                font-family: Roboto, sans-serif;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%; /* 38.4px */
                @media (max-width: $mobile) {
                    font-size: 16px;
                    line-height: unset;
                }
            }
        }
    }
}