@import "../../../../styles/variables.scss";

.account-deletions {
    padding: 0px 80px;
    @media(max-width: $mobile) {
        padding: 0px 40px;
    }
    h1.title {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 2rem;
        color: #fff;
    }
    p.value {
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 1rem;
        color: #fff;
        a {
            color: #B0A2AF;
            text-decoration: underline;
        }
    }
}