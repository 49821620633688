html,
body {
	padding: 0 !important;
	margin: 0 !important;
	height: 100%;
	scroll-behavior: smooth;
  	font-family: 'Roboto', sans-serif;
	background: linear-gradient(118deg, #383456 0%, #1F1E28 84.69%);
	background-size: cover;
	background-repeat: no-repeat;
}

.ReactModal__Overlay {
	z-index: 101 !important;
}

a {
	color: inherit;
	text-decoration: none;
}

p {
	margin: 0;
}
