@import '../../../styles/colors';
@import '../../../styles/mixins';

.button {
	width: 164px;
	height: 32px;
	border-radius: 1px;
	border: none;
	cursor: pointer;

	&:active {
		opacity: 90%;
	}

	&-primary {
		@include background-primary;
		@extend .button;
		box-shadow: 0px 0px 8px 0px #e6007d66;

		&:disabled {
			background-color: #e6007db2;
			opacity: 100%;
		}
	}

	&-secondary {
		@include background-secondary;
		@extend .button;
		box-shadow: 0px 0px 8px 0px #ffffff66;

		&:disabled {
			background-color: #b1a1c6;
			box-shadow: unset;
			cursor: not-allowed;
			opacity: 100%;
		}
	}

	&-tertiary {
		@include background-tertiary;
		@extend .button;
		box-shadow: 0px 0px 8px 0px #aaaaaa66;

		&:disabled {
			cursor: not-allowed;
			color: $text-black;
			opacity: 100%;
		}
	}

	&-primary-round {
		@include background-primary;
		@extend .button;
		border-radius: 32px;
		background: linear-gradient(93deg, #DF14CB 0%, #7C0970 100%);
		padding: 12px 24px;
		width: unset;
		height: unset;

		&:disabled {
			background-color: #e6007db2;
			opacity: 100%;
		}
	}

	.children-wrapper {
		display: inline-block;
		vertical-align: middle;
		font-weight: 600;
		font-size: 12px;
		line-height: 15px;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		width: 90%;

		&.fix-primary-round {
			width: 100%;
		}
	}
}

.parallelogram {
	transform: skew(-10deg);
	margin-left: 3px;
}

.fix-parallelogram {
	transform: skew(10deg);
}

.block {
	width: 100% !important;

	&-parallelogram {
		width: calc(100% - 6px) !important;
	}
}
