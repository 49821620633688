$dark-sea-green: #159953;
$white: #ffffff;
$light-white: #f3f3f3;
$black: #000000;
$light-black: #242424;
$gray: #aaaaaa;
$light-gray: #eeeeee;
$pink: #e6007d;
$purple: #b1a1c6;
$nav: rgba(0,0,0,0.45);

// Primary colors
$color-primary: $pink;
$color-secondary: $white;
$color-tertiary: $gray;

// State colors
$state-success: #33c199;
$state-danger: #e30008;
$state-warning: #efa906;
$state-disabled-dark: #787a7d;
$state-disabled-light: #cbcccd;
$state-disabled-purple: $purple;

// Text colors
$text-black: $black;
$text-white: $white;
